/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  background-color: white;
}

/* Nav Bar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; 
}

.navbar.scrolled {
  position: fixed; 
  top: 0; 
  left: 0; 
  z-index: 1000; 
  background-color: rgba(255, 255, 255, 0.863); 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); 
}

.logo img {
  height: 100px;
  padding-left: 100px;
}

/* Default Nav Links */
.nav-links {
  list-style: none;
  display: flex;
  gap: 1.5em;
  padding-right: 100px;
}

.nav-links li {
  margin-left: -10px; 
}

.nav-links li a {
  color: #004c70;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s, padding-left 0.3s; 
}

.nav-links li a:hover {
  color: #2E8B57;
}

/* Scrolled Nav Links */
.scrolled .nav-links li a {
  color: #004c70; 
  padding-left: 5px; 
}

/* Hero Section */
.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4em 2em;
  margin-top: 0; 
  background-color: #004c70; 
  color: #fff;
}

.hero-left {
  width: 50%;
}

.hero-right img {
  width: 80%;
  max-width: 800px;
  border-radius: 20px;
}


/* About Section */
.about {
  padding: 3em;
  background-color: #fff;
}

.about h2 {
  color: #004c70;
  margin-bottom: 1em;
}

/* Mission Section */
.mission {
  padding: 3em;
  background-color: #f0f8ff;
}

.mission h2 {
  color: #2E8B57;
  margin-bottom: 1em;
}

/* Services Section with Boxes */
.services {
  padding: 2em;
  background-color: #fff;
  text-align: center;
}

.services h2 {
  font-size: 2em;
  color: #004c70;
  margin-bottom: 1em;
}

/* Container for services to center the service boxes */
.services-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; 
  gap: 1.5em;
  margin-top: 2em; 
}

.service-box {
  padding: 1.5em;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  transition: transform 0.3s;
  width: 250px; 
}

.service-box:hover {
  transform: translateY(-10px);
}

.service-box h4 {
  color: #2E8B57;
  margin-bottom: 0.5em;
}

.service-box p {
  color: #555;
}


/* Footer Styles */
.footer {
  padding: 2em;
  background-color: #004c70; 
  color: #fff; 
}

.footer-content {
  display: flex;
  justify-content: space-between; 
  align-items: center;
}

.footer-logo {
  display: flex;
  align-items: center; 
}

.footer-logo img {
  height: 100px; 
  margin-right: 1em;
}

.footer-contact h2 {
  margin: 0;
}

.footer-contact p {
  margin: 0.5em 0; 
}

